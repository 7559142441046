$tablet: 768px;
$tablet-large: 1024px;
$desktop: 1280px;
$desktop-middle: 1440px;
$desktop-primary: 1920px;
$desktop-large: 2500px;

$primary: #07524E;
$dark-primary: #07524E;
$secondary: #E3EAE3;
$bright: #FD8465;
$dark: #191919;
$light: #F6F6F6;
$disabled: #A5B5B4;
$muted: #858585;
$white: #FFFFFF;
$error: #EC5D54;
$blackout: rgba(25,25,25,.8);

$text-base: $dark;
$text-base-opacity: rgba(25,25,25,.8);
$text-muted: #858585;
$text-muted-inverted: rgba(255, 255, 255, 0.7);
$text-inverted: $secondary;
$text-title: $white;
$text-disabled: $disabled;

$border-color: rgba(133, 133, 133, .5);

$button-bgc-disabled: #b2b2b2;

$_banners-gradient-color: #1E6764;

$container-width: 1312px;
$container-width-wide: 1792px;
$container-padding-x-dt: 64px;
$container-padding-x: 32px;
$container-padding-y: 48px;
$container-padding-y-dt: 120px;
$custom-scroll-width: 6px;

$gap: 8px;
$gap-2: 16px;
$gap-3: 24px;
$gap-4: 32px;
$gap-6: 48px;
$gap-7: 56px;
$gap-8: 64px;
$gap-9: 72px;
$gap-12: 96px;
$gap-15: 120px;

$header-height: 88px;
$header-height-scrolled: 64px;
$header-height-dt: 100px;

$button-min-width-dt: 304px;

$font-family: 'Aeonik', sans-serif;
$font-family-flag-emoji-polyfill: 'Twemoji Country Flags';

$font-size-base: 14px;
$font-size-small: 12px;
$font-size-middle: 16px;
$font-size-large: 24px;
$font-size-title: 40px;
$font-size-large-uppercase: 20px;
$font-size-supertitle-large: 200px;
$font-size-supertitle-middle: 120px;
$font-size-supertitle-small: 70px;

$font-size-h1: 42px;
$font-size-h1-dt-sm: 56px;
$font-size-h1-dt: 80px;

$font-size-h2: 24px;
$font-size-h2-dt: 32px;

$font-size-h3: 22px;
$font-size-h3-dt: 24px;

$font-size-h4: 16px;
$font-size-h4-dt: 22px;

$field-height: $gap-7;

$z-index-header: 11;
$z-index-blur: 10;
$z-index-jump-menu: 10;

$button-bgc-enabled: #191919;
$button-bgc-hovered: $bright;
$button-bgc-pressed: #2f2f2f;
$button-bgc-disabled: #b2b2b2;
$inverted-button-bgc-enabled: #f6f6f6;
$inverted-button-bgc-hovered: #e0e0e0;
$inverted-button-bgc-pressed: #d5d5d5;
$inverted-button-bgc-disabled: #b2b2b2;

@import "variables";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $gap-8;
  padding: 0 36px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  font-family: $font-family;
  text-transform: uppercase;
  border-width: 0;
  transition: color 0.2s ease-out, background-color 0.2s ease-out;

  &--inverted {
    color: $text-base;
    background-color: $text-inverted;
  }

  &:hover {
    color: $text-title;
    background-color: $bright;
    text-decoration: none;
    cursor: pointer;
  }

  &--dark {
    color: $text-title;
    background-color: $dark;

    &:hover {
      background-color: $primary;
    }
  }

  &--transparent {
    background-color: transparent;
  }

  &:disabled,
  &--disabled {
    color: $text-base;
    background-color: $disabled;

    &:hover {
      color: $text-base;
      cursor: initial;
      background-color: $disabled;
    }
  }

  @include above($desktop) {
    min-width: $button-min-width-dt;
  }
}

.button-link {
  position: relative;
  flex-shrink: 0;
  padding: 0;
  font-family: $font-family;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
  white-space: nowrap;
  color: $text-base;
  background: none;
  border-width: 0;
  cursor: pointer;

  &:hover {
    text-decoration: none;

    &::before {
      width: 0;
    }
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transition: width .2s cubic-bezier(.8, 0, .5, .8);
  }
}

.tag {
  padding: 6px 14px;
  text-transform: uppercase;
  font-size: $font-size-small;
  font-weight: 500;
  line-height: 1.2;
  color: $text-base;
  background-color: $secondary;
  border-radius: 50px;
  white-space: nowrap;
  transition: color 0.2s ease-out, background-color 0.2s ease-out;

  &--primary {
    color: $text-inverted;
    background-color: $primary;
  }

  &--inverted {
    color: $primary;
    background-color: rgba(255, 255, 255, 0.5);
  }

  &--primary-light {
    color: $primary;
    background-color: $secondary;
  }

  &--bright {
    color: $dark;
    background-color: $bright;
  }

  &--small {
    padding: 5px 12px;
    font-size: 10px;
  }

  @include above($desktop) {
    padding: 8px 14px;
    font-size: $font-size-middle;

    &--small {
      font-size: 10px;
    }

    &--large {
      padding: 9px 16px;
      font-size: $font-size-large;
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h3,
h4 {
  font-weight: 900;
}

ul li {
  list-style: disc;
  list-style-position: inside;
  padding: 0;
  margin: 0;
  padding-inline-start: 0;
  margin-inline-start: 0;
}

ol li {
  list-style: decimal;
  list-style-position: inside;
}

ul, ol {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

.field {
  width: 100%;
  height: $field-height;
  padding: $gap-2;
  font-size: 20px;
  font-weight: 500;
  font-family: $font-family;
  background-color: $secondary;
  background-image: none;
  color: $text-base;
  border: 1px solid $secondary;
  border-radius: 0;
  outline: none;

  &::placeholder {
    font-family: $font-family;
    font-weight: normal;
    color: $disabled;
  }

  &:focus {
    outline: none;
  }

  &--textarea {
    height: auto;
    min-height: calc(#{$gap-2} * 2 + 2px + 5em);
  }

  &-error {
    display: block;
    font-size: $font-size-small;
    font-weight: 500;
    color: $error;
  }
}

.checkbox {
  $selfCheckbox: &;
  display: flex;
  gap: 20px;

  &:hover {
    cursor: pointer;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      +#{$selfCheckbox}__checkmark {
        background-color: currentColor;
        border-color: currentColor;

        svg {
          opacity: 1;
        }
      }
    }
  }

  &__checkmark {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: 2px solid $disabled;
    border-radius: 2px;

    svg {
      position: relative;
      width: 14px;
      height: auto;
      opacity: 0;
    }

    path {
      stroke: $primary;
      stroke-width: 2px;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    min-height: 24px;
    margin-top: -2px;
    font-family: $font-family;
    line-height: 1.2;
    font-weight: normal;
    font-size: $font-size-middle;
    color: $disabled;

    a {
      font-family: $font-family;
      white-space: nowrap;
      color: currentColor;
      background-image:
        linear-gradient(to right, rgba(165, 181, 180, 1), rgba(165, 181, 180, 1)),
        linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
      background-size: 100% 1px;
      background-position: 0 100%;
      background-repeat: no-repeat;
      transition: background-size .2s ease-out;

      &:hover {
        text-decoration: none;
        background-size: 0 1px;
      }
    }
  }
}

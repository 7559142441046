.text-underline {
  background-image: linear-gradient(to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)),
    linear-gradient(to right, currentColor, currentColor);
  background-size: 100% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.3s ease-out;
}

.h1 {
  @include h1;
}

.h2 {
  @include h2;
}

.h3 {
  @include h3;
}

.h4 {
  @include h4;
}

p {
  margin: 0 0 1.2em;

  &:last-child {
    margin-bottom: 0;
  }
}

strong {
  font-weight: 600;
}

.text-bright {
  color: $bright;
}

a {
  color: $primary;

  &:hover {
    text-decoration: underline;
  }
}

ol {
  margin-bottom: 1.2em;
  counter-reset: my-awesome-counter;

  &:last-child {
    margin-bottom: 0;
  }

  >li {
    position: relative;
    padding-left: 1.5em;
    list-style: none;
    counter-increment: my-awesome-counter;

    &::before {
      content: counter(my-awesome-counter) ". ";
      position: absolute;
      top: 0;
      left: 0;
      color: $bright;
    }

    +li {
      margin-top: 1em;
    }
  }
}

.card--slider .card__text,
.banner__text {
  a {
    color: $bright;
  }
}

.section {

  &--dark,
  &--primary {

    p,
    .tab__text,
    .text-host-component {
      a {
        color: $bright;
      }
    }

    .tab__text {
      h2 {
        color: $text-title;
      }
    }
  }
}

.cards-list--colored {
  symfa-card {

    &:nth-child(8n + 1),
    &:nth-child(8n + 4),
    &:nth-child(8n + 6),
    &:nth-child(8n + 7) {
      .card {
        a {
          color: $bright;
        }
      }
    }
  }
}

.article__body-text {
  ol {
    li {
      margin-bottom: .5em;
    }
  }
}

.tab__text {
  h2 {
    margin: 1.9em 0 0.7em;
    font-size: $font-size-h3;
    font-weight: 500;
    line-height: 1.2;
    opacity: 1;

    &:first-child {
      margin-top: 0;
    }
  }

  @include above($desktop) {
    h2 {
      font-size: $font-size-title;
    }
  }
}
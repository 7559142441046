@import 'variables';

@mixin itemsWidthBetweenGaps($gap, $elsAmount) {
  width: calc(calc(100% - (($elsAmount - 1) * $gap)) / $elsAmount);
}

@mixin dev-mode($component: 'devmode') {
  position: relative;
  min-height: 30px;
  border: 1px dashed #c5c5c5;

  &::before {
    content: $component;
    position: absolute;
    top: 0;
    right: 8px;
    color: #b9b9b9;
    z-index: 3;
  }
}

@mixin below($width, $orientation: 'width') {
  @media screen and (max-#{$orientation}: $width - 1px) {
    @content;
  }
}

@mixin above($width, $orientation: 'width') {
  @media screen and (min-#{$orientation}: $width) {
    @content;
  }
}

@mixin between($minWidth, $maxWidth, $orientation: 'width') {
  @media screen and (min-#{$orientation}: $minWidth) and (max-#{$orientation}: $maxWidth) {
    @content;
  }
}

@mixin container {
  width: 100%;
  max-width: ($container-width + ($container-padding-x-dt * 2));
  margin: 0 auto;
  padding-left: $container-padding-x;
  padding-right: $container-padding-x;

  @include above($tablet) {
    padding-left: $container-padding-x-dt;
    padding-right: $container-padding-x-dt;
  }
}

@mixin h1 {
  font-weight: 500;
  font-size: $font-size-h1;
  line-height: 1;
  color: $text-base;

  @include above($desktop) {
    font-size: $font-size-h1-dt-sm;
  }

  @include above($desktop-middle) {
    font-size: $font-size-h1-dt;
  }
}

@mixin h2 {
  font-size: $font-size-h2;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  color: $text-base;

  @include above($desktop) {
    font-size: $font-size-h2-dt;
  }
}

@mixin h3 {
  font-size: $font-size-h3;
  font-weight: 500;
  line-height: 1.2;
  color: $text-base;

  @include above($desktop) {
    font-size: $font-size-h3-dt;
  }
}

@mixin h4 {
  font-size: $font-size-h4;
  font-weight: 500;
  line-height: 1.2;
  color: $text-base;

  @include above($desktop) {
    font-size: $font-size-h4-dt;
  }
}

@mixin text {
  font-weight: normal;
  color: $text-base;
  opacity: 0.8;
}

@mixin description {
  font-size: $font-size-middle;
  line-height: 1.2;
  font-weight: 500;

  @include above($desktop) {
    font-size: $font-size-large;
  }
}

@mixin button($size: 'big') {
  $self: &;
  @if $size == 'small' {
    height: $gap-6;
    text-transform: capitalize;
    font-size: 18px;
  } @else if $size == 'big' {
    height: $gap-8;
    text-transform: uppercase;
    font-size: 20px;
  }

  color: $text-title;
  background-color: $dark;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 0 40px;
  line-height: 1;
  font-family: $font-family;
  border-width: 0;
  transition: color 0.2s ease-out, background-color 0.2s ease-out;
  cursor: pointer;

  &:hover,
  &:active {
    color: $text-base;
    background-color: $bright;
  }

  &:disabled {
    background-color: $button-bgc-disabled;
    color: $text-muted;

    #{$self}:hover {
      background-color: $button-bgc-disabled;
      color: $text-muted;
    }
  }
}

@mixin button-inverted {
  $self: &;
  color: $text-base;
  background-color: $light;

  &:hover,
  &:active {
    color: $text-base;
    background-color: $bright;
  }

  &:disabled {
    background-color: $button-bgc-disabled;
    color: $text-muted;

    #{$self}:hover {
      background-color: $button-bgc-disabled;
      color: $text-muted;
    }
  }
}

@mixin text-button($size: 'big', $inverted: 'false') {
  $self: &;
  @if $size == 'small' {
    text-transform: capitalize;
    font-size: 18px;
  } @else if $size == 'big' {
    text-transform: uppercase;
    font-size: 20px;
  }

  @if $inverted == 'true' {
    color: $text-title;
  } @else if $inverted == 'false' {
    color: $text-base;
  }

  position: relative;
  font-weight: 500;
  line-height: 1;
  font-family: $font-family;
  border-width: 0;
  padding: 0;
  margin: 0;
  transition: color 0.2s ease-out, background-color 0.2s ease-out;
  cursor: pointer;
  background-color: transparent;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 0;
    height: 1px;
    background-color: $bright;
    transition: width 0.2s ease-out;
  }

  &:hover,
  &:active {
    color: $bright;
    &::after {
      width: 100%;
    }
  }

  &:disabled {
    color: $text-muted;

    #{$self}:hover {
      color: $text-muted;
      &::after {
        width: 0;
      }
    }
  }
}

@mixin button-transparent-inverted {
  color: $text-title;
  background-color: transparent;
  opacity: 1;
  transition: color 0.15s, opacity 0.15s;

  &:hover {
    opacity: 0.8;
    background-color: transparent;
  }
}

@mixin tag {
  display: flex;
  align-items: center;
  height: 20px;
  margin: 0;
  padding: 2px 14px 0;
  list-style: none;
  font-family: $font-family;
  font-size: 8px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  color: $text-base;
  background-color: $secondary;
  border-radius: 20px;

  @include above($desktop) {
    height: 27px;
    padding-top: 0;
    font-size: 10px;
  }
}

@mixin card-article {
  $selfCardArticle: &;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &:hover {
    text-decoration: none;
  }

  &-wrapper {
    height: 100%;
  }

  &__image {
    position: relative;
    height: 0;
    padding-top: 50%;

    &-container {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding: 10px 10px 18px;
    background-color: $primary;

    &-top {
      min-height: 120px;
      margin-bottom: 22px;
    }
  }

  &__title {
    @include h4;
    margin: 0 0 8px;
    padding-right: 16px;
    color: $text-title;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      font-weight: 500;
      font-family: $font-family;
    }
  }

  &__text {
    position: relative;
    margin: 0;
    font-family: $font-family;
    font-size: $font-size-small;
    font-weight: normal;
    line-height: 1.2;
    color: $text-inverted;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    z-index: 2;
  }

  @include above($tablet) {
    &__image {
      padding-top: 60%;
    }
  }

  @include above($desktop) {
    &:hover {
      #{$selfCardArticle}__title {
        span {
          background-size: 0 1px, 100% 1px;
        }
      }
    }

    &__image {
      padding-top: 70%;
    }

    &__info {
      display: block;
      padding: 20px 16px 0;
      background-color: transparent;

      &-top {
        min-height: initial;
      }
    }

    &__title {
      font-weight: 500;
      color: $text-base;

      span {
        background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
          linear-gradient(to right, rgba(133, 133, 133, 1), rgba(133, 133, 133, 1));
        background-size: 100% 1px, 0 1px;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
        transition: background-size 0.4s ease-out;
      }
    }

    &__text {
      font-size: $font-size-middle;
      color: $text-base;
    }
  }
}

@mixin grid {
  background-color: $primary;
  background-image: repeating-linear-gradient(
      90deg,
      $_banners-gradient-color,
      $_banners-gradient-color 1px,
      transparent 0px,
      transparent 25px
    ),
    repeating-linear-gradient(
      180deg,
      $_banners-gradient-color,
      $_banners-gradient-color 1px,
      transparent 0px,
      transparent 25px
    );
  background-size: 26px 26px;
}

@mixin custom-scrollbar(
  $scrollbar-thumb-color: $disabled,
  $scrollbar-bg-color: $white,
  $height: 12px,
  $border-radius: 6px
) {
  &::-webkit-scrollbar {
    width: 6px;
    height: $height;
    outline: none;
    opacity: 0;
    background-color: $scrollbar-bg-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
    outline: none;
    border-radius: $border-radius;
  }
}

@mixin li-with-border($color: $text-base) {
  li {
    margin: 0 0 1em 1.5em;
    list-style: none;
    color: $color;

    @include above($desktop) {
      font-size: $font-size-large-uppercase;
    }

    &::before {
      content: '— ';
      font-weight: 700;
      color: $bright;
      display: inline-block;
      width: 1.5em;
      margin-left: -1.5em;
    }
  }
}

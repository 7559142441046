@import "variables";

.section {
  .swiper-scrollbar-drag {
    background: $bright;
  }

  swiper {
    > .swiper.s-wrapper.swiper-container-horizontal {
      > .swiper-scrollbar {
        height: 4px;
      }
    }
  }
}

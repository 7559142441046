@import 'variables';
@import 'mixin';

app-root {
  display: block;
}

body {
  position: relative;
  color: $dark;
  background-color: $light;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  line-height: 1.2;
  min-width: 300px;
  overflow: hidden;
  touch-action: none;
  height: 100vh;
  font-size: $font-size-middle;
  font-weight: normal;
  font-family: $font-family-flag-emoji-polyfill, $font-family;

  &.scrollable {
    overflow-y: auto;
    touch-action: auto;
    height: 100%;
    @include custom-scrollbar;

    &.blur {
      .page-wrapper {
        &::before {
          opacity: 1;
          z-index: $z-index-blur;
          transition: opacity 0.3s 0.1s ease-in-out, z-index 0s ease-in-out;
        }
      }
    }

    &.scale {
      .page-wrapper {
        transform: scale(1.2);
        transition: transform 0.4s 0.1s ease-in-out;
      }
    }
  }

  @include above($desktop) {
    &:not(.scrollable) {
      padding-right: $custom-scroll-width;

      .header {
        right: $custom-scroll-width;
      }
    }
  }
}

.page-wrapper {
  transform: scale(1);
  transform-origin: top center;
  transition: transform 0.3s ease-in-out;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(217, 217, 217, 0.01);
    backdrop-filter: blur(16px);
    transition: opacity 0.3s ease-in-out, z-index 0s 0.3s ease-in-out;
    opacity: 0;
    z-index: -1;
  }
}

.container {
  @include container;

  &--wide {
    max-width: ($container-width-wide + ($container-padding-x-dt * 2));
  }
}

// list from editor .point-list__list
ul:not(
    .breadcrumbs__list,
    .article-menu,
    .social-links,
    .menu,
    .tags,
    .option__list,
    .dropdown-list,
    .multiselect-dropdown__list,
    .featured-jobs-card__tags,
    .search-hints,
    .jobs-search-results__list,
    .jobs-result-card__tags
  ) {
  margin-top: 16px;
  @include li-with-border(currentColor);
}

.primary-li-before ul {
  li {
    &::before {
      color: $dark-primary;
    }
  }
}

.no-margin-symfa-text symfa-text .text {
  margin: 0;
}

.no-margin-ul ul {
  margin: 0;
}

symfa-text .text ul > li {
  font-size: $font-size-middle;

  @include above($desktop) {
    font-size: $font-size-large;
  }
}

symfa-section[backgroundcolor='secondary'] {
  + symfa-section[backgroundcolor='secondary'] {
    .section {
      padding-top: 0;
    }
  }
}

.ng-tooltip {
  position: absolute;
  max-width: 150px;
  font-size: 14px;
  text-align: center;
  color: $light;
  padding: 4px 8px;
  background: $dark;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
}
.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}
.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: $dark transparent transparent transparent;
}
.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent $dark transparent;
}
.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent $dark;
}
.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent $dark transparent transparent;
}
.ng-tooltip-show {
  opacity: 1;
}
